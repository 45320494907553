import { Card, IconButton, Tabs } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import useChallengeHandler from '../../../hook/useChallengeHandler';
import { useAppSelector } from '../../../store';
import { CampaignType } from '../../../store/dataTypes';
import { getJsonApiData } from '../../../utils/formatData';
import ChallengeHeaders from '../../json/columnHeaders/ChallengeHeaders.json';
import FocusedChallengeHeaders from '../../json/columnHeaders/FocusedChallengeHeaders.json';
import FocusedChallengeData from '../../json/mock/mockingFocusedChallengeData.json';
import CompleteChallengeHeaders from '../../json/columnHeaders/CompleteChallengeHeaders.json';
import CompleteChallengeData from '../../json/mock/mockingCompleteChallengeData.json';
import ChallengeAttemptsHeaders from '../../json/columnHeaders/ChallengeAttemptsHeaders.json';
import ChallengeAttemptsData from '../../json/mock/mockingChallengeAttemptsData.json';
import StyledTabs from '../../../components/Tabs/Tabs';

const FlexBtn = styled('div', {
  display: 'flex',
  gap: '$1'
})


const ChallengeTab: React.FC = () => {
  const navigate = useNavigate();
  const { getChallengesByCampaign, deleteChallenge, getChallengeStatus } = useChallengeHandler();
  const campaignDetailData: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const [challenges, setChallenges] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [importChallengeStatus, setImportChallengeStatus] = useState(false);
  const [listType, setListType] = useState('challenges');
  const [focusedChallenges, setFocusedChallenges] = useState<any[]>(FocusedChallengeData);
  const [completeChallenges, setCompleteChallenges] = useState<any[]>(CompleteChallengeData);
  const [challengeAttempts, setChallengeAttempts] = useState<any[]>(ChallengeAttemptsData);
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 0,
    limit: 25,
    search: ''
  })

  const fetchData = async () => {
    setLoading(true)
    await getChallengesByCampaign(campaignDetailData?.uuid, requestData, true)
      .then(res => {
        const formatData = getJsonApiData(res, true)
        setChallenges(formatData.data)
        setRequestData(prevState => ({ ...prevState, total: formatData.total }))
      })
      .finally(() => setLoading(false))

    await getChallengeStatus(campaignDetailData?.id).then((error) => {
      if (error?.message) setImportChallengeStatus(true);
      else setImportChallengeStatus(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChallenge = (value) => {
    requestData.search = value
    fetchData();
  };

  const debouncedSearch = useCallback(
    debounce((value) => handleSearchChallenge(value), 250),
    []
  );

  const handleChange = (value) => {
    setSearch(value);
    debouncedSearch(value)
  }

  const handleRemove = (uuidEntity: string) => {
    deleteChallenge(uuidEntity).then(() => {
      fetchData();
    });
  };

  function InsertActions(data: any[], listType: string) {
    let aux: any[] = [];

    const actions = (uuidEntity: string, listType, data) => (
      <FlexContainer display="inline-flex" gap="1rem">
        {listType === 'focused_challenges' ? (
          <>
            <IconButton
              icon={Edit2}
              variant="inherit"
              onClick={() => {
                navigate(
                  `/bees-games/manage-assets/campaign-details/${campaignDetailData.id}/edit-focused-challenge/${data.id}`,
                  {
                    state: {

                    },
                  }
                );
              }}
            />
            <ModalDelete
              handleDelete={() => ''}
              trigger={<IconButton icon={Trash2} variant="inherit" />}
              title="Delete the focused challenge?"
              description={
                <>
                  Do you really wish to delete this focused challenge?
                  <br />
                  This action can’t be undone.
                </>
              }
            />
          </>
        ) : listType === 'complete_challenges' ? (
          <ModalDelete
            handleDelete={() => handleRemove(uuidEntity)}
            trigger={<IconButton icon={Trash2} variant="inherit" />}
            title="Delete the complete challenge?"
            description={
              <>
                Do you really wish to delete this complete challenge?
                <br />
                This action can’t be undone.
              </>
            }
          />
        ) : (
          <ModalDelete
            handleDelete={() => handleRemove(uuidEntity)}
            trigger={<IconButton icon={Trash2} variant="inherit" />}
            title="Delete the challenge link to the campaign?"
            description={
              <>
                Do you really wish to delete this link between the challenge and the campaign?
                <br />
                This action can’t be undone.
              </>
            }
          />
        )}
      </FlexContainer>
    );

    for (let i = 0; i < data.length; i++) {
      let item: any = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item?.uuidEntity, listType, data[i]),
      });
    }

    return aux;
  }

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  return (
    <>
      {importChallengeStatus && !loading && (
        <StyledAlert
          message={'You already have an import running. Wait for it to finish and try again!'}
          type={'warning'}
          css={{ width: '100%', marginBottom: 20 }}
        />
      )}
      <Card elevated={'medium'} border={'large'}>
      <StyledTabs defaultValue="challenges" value={listType}>
        <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
          <Tabs.Trigger value="challenges" onClick={() => setListType('challenges')}>
            Challenges
          </Tabs.Trigger>
          <Tabs.Trigger value="focused_challenges" onClick={() => setListType('focused_challenges')}>
            Player focused challenges
          </Tabs.Trigger>
          <Tabs.Trigger value="complete_challenges" onClick={() => setListType('complete_challenges')}>
            Player complete challenges
          </Tabs.Trigger>
          <Tabs.Trigger value="challenge_attempts" onClick={() => setListType('challenge_attempts')}>
            Player challenge attempts
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="challenges" style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 20px',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              id="challenge"
              value={search}
              onChange={(e) => handleChange(e.currentTarget.value)}
              onClear={() => handleSearchChallenge('')}
            />
          </div>
          <FlexBtn>
            <StyledButton
              variant="primary"
              icon={Plus}
              disabled={loading || importChallengeStatus}
              leading
              onClick={() =>
                navigate(`/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/add-challenge/`)
              }
            >
              Add Challenge
            </StyledButton>
            <StyledButton
              variant="primary"
              disabled={loading || importChallengeStatus}
              icon={Plus}
              leading
              onClick={() =>
                navigate(
                  `/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/import-challenges`
                )
              }
            >
              Import Challenges
            </StyledButton>
            <StyledButton
              variant="primary"
              icon={Plus}
              disabled={loading || importChallengeStatus}
              leading
              onClick={() =>
                navigate(`/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/add-challenge-local`)
              }
            >
              Add Local
            </StyledButton>
            <StyledButton
              variant="primary"
              disabled={loading || importChallengeStatus}
              icon={Plus}
              leading
              onClick={() =>
                navigate(
                  `/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/import-challenges-local`
                )
              }
            >
              Import Local
            </StyledButton>
          </FlexBtn>
        </div>
        <StyledTable
          loading={loading}
          columns={ChallengeHeaders}
          data={InsertActions(challenges, 'challenges')}
          pagination={false}
        />
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: "wrap"
          }}
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </div>
        </Tabs.Content>
        <Tabs.Content value="focused_challenges" style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 20px',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              id="challenge"
              value={search}
              onChange={(e) => handleChange(e.currentTarget.value)}
              onClear={() => handleChange('')}
            />
          </div>
          <FlexBtn>
            <StyledButton
              variant="primary"
              disabled={loading || importChallengeStatus}
              icon={Plus}
              leading
              onClick={() =>
                navigate(
                  `/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/import-focused-challenge`
                )
              }
            >
              Import
            </StyledButton>
          </FlexBtn>
        </div>
        <StyledTable
          loading={loading}
          columns={FocusedChallengeHeaders}
          data={InsertActions(focusedChallenges, 'focused_challenges')}
          pagination={false}
        />
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: "wrap"
          }}
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </div>
        </Tabs.Content>
        <Tabs.Content value="complete_challenges" style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 20px',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              id="challenge"
              value={search}
              onChange={(e) => handleChange(e.currentTarget.value)}
              onClear={() => handleChange('')}
            />
          </div>
          <FlexBtn>
            <StyledButton
              variant="primary"
              disabled={loading || importChallengeStatus}
              icon={Plus}
              leading
              onClick={() =>
                navigate(
                  `/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/import-complete-challenge`
                )
              }
            >
              Import
            </StyledButton>
          </FlexBtn>
        </div>
        <StyledTable
          loading={loading}
          columns={CompleteChallengeHeaders}
          data={InsertActions(completeChallenges, 'complete_challenges')}
          pagination={false}
        />
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: "wrap"
          }}
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </div>
        </Tabs.Content>
        <Tabs.Content value="challenge_attempts" style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 20px',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              id="challenge"
              value={search}
              onChange={(e) => handleChange(e.currentTarget.value)}
              onClear={() => handleChange('')}
            />
          </div>
        </div>
        <StyledTable
          loading={loading}
          columns={ChallengeAttemptsHeaders}
          data={InsertActions(challengeAttempts, 'challenge_attempts')}
          pagination={false}
        />
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: "wrap"
          }}
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </div>
        </Tabs.Content>
      </StyledTabs>
      </Card>
    </>
  );
};

export default ChallengeTab;
