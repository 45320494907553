import {
  Button,
  Card,
  Heading,
  IconButton,
  Image,
  Paragraph,
  TextButton
} from '@hexa-ui/components';
import { ChevronRight, HelpCircle } from '@hexa-ui/icons';

import { Link } from 'react-router-dom';
import FlexContainer from '../../components/FlexContainer';

import { useAuthenticationService } from 'admin-portal-shared-services';
import useWindowScale from '../../hook/useWindowScale';

const authentication = useAuthenticationService();

const Home = () => {
  const name = authentication.getUserFullNameB2C().given_name;
  const isAugmentedScale = useWindowScale();

  const ImageScaleStyle = {
    height: isAugmentedScale ? '120px' : '230px',
    width: '100%',
  }

  const CardScaleStyle = {
    height: isAugmentedScale ? '255px' : '460px',
    width: isAugmentedScale ? '250px' : '350px',
  }

  return (
    <div className='content-home'>
      <Heading type="H3">BEES Games</Heading>
      <Card elevated={'medium'} border={'small'} css={{ padding: '1rem', margin: isAugmentedScale ? '0.5rem 0px 1.5rem' : '1rem 0 2rem 0' }}>
        <FlexContainer display="flex" justifyContent="space-between">
          <div style={{ maxWidth: '800px' }}>
            <Paragraph weight="semibold" color="#757575">
              Hello, {name[0]?.toUpperCase() + name.substring(1)}
            </Paragraph>
            <Paragraph weight="normal">
              Welcome back to BEES Games! Please read our documentation for a step-by-step guide on
              every feature available, or watch our Video Tutorials for quick reference
            </Paragraph>
          </div>
          <Card
            elevated={'medium'}
            border={'small'}
            css={{
              padding: '0.5rem',
              display: 'inline-flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <IconButton variant="tertiary" icon={HelpCircle} />

            <Link
              style={{
                textDecoration: 'none',
              }}
              to={
                'https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g'
              }
              target="_blank"
            >
              <TextButton icon={ChevronRight} iconPosition="trailing">
                Show guide
              </TextButton>
            </Link>
          </Card>
        </FlexContainer>
      </Card>
      <Paragraph weight="semibold" css={{ fontSize: '1.75rem' }}>
        Quick access
      </Paragraph>
      <FlexContainer display="flex" margin="1rem 0 1rem 0" gap="1rem" flexWrap="wrap">
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image style={ImageScaleStyle} src={require('../../assets/phone.png')} containerCss={{ width: '100%' }} />
          <FlexContainer
            height='100%'
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent='space-between'
          >
            <div>
              <Heading size="H4">Create campaign</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Customize a campaign with a game and prizes</Paragraph>
              </div>
            </div>

            <Link
              to={'/bees-games/manage-assets/create-campaign'}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Create
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image style={ImageScaleStyle} src={require('../../assets/box.png')} containerCss={{ width: '100%' }} />
          <FlexContainer
            height='100%'
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap={isAugmentedScale ? "0" : "0.75rem"}
            flexWrap="wrap"
            justifyContent='space-between'
          >
            <div>
              <Heading size="H4">Create prize</Heading>
              <div style={{ marginTop: isAugmentedScale ? '5px' : '14px' }}>
                <Paragraph>Add prizes to award your users</Paragraph>
              </div>
            </div>

            <Link to={'/bees-games/manage-assets/create-prize'} style={{ textDecoration: 'none' }}>
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Create
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card
          elevated="medium"
          border="small"
          style={CardScaleStyle}
          css={{
            overflow: 'hidden',
            display: 'flex'
          }}
        >
          <Image style={ImageScaleStyle} src={require('../../assets/pocs.png')} containerCss={{ width: '100%' }} />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Account list</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Make it easy to import POCs or create a new poc information</Paragraph>
              </div>
            </div>
            <Link
              to={'/bees-games/account-list'} style={{ textDecoration: 'none' }}
            >
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Account list
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        {/* <Card
          elevated="medium"
          border="small"
          style={CardScaleStyle}
          css={{
            overflow: 'hidden',
            display: 'flex'
          }}
        >
          <Image style={ImageScaleStyle} src={require('../../assets/boxes.png')} containerCss={{ width: '100%' }} />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Rewards challenges</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>
                  ...
                </Paragraph>
              </div>
            </div>
            <Link
              to={'/bees-games/rewards-challenges'}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Create
              </Button>
            </Link>
          </FlexContainer>
        </Card> */}
      </FlexContainer>
    </div>
  );
};
export default Home;
